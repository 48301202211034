// VisitLog The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "autocompleteUrl", "autocompleteList", "autocompleteContainer", "materialDescription", "materialUom" ]

	// autoCapitalize() {
	// 	this.inputFieldTarget.value = this.inputFieldTarget.value.toUpperCase()
	// }

  executeAutocomplete() {
    if (event.target.value.length <= 1) {
			this.clearAutocompleteList()
      return false
    }

    this.executeAjax(event.target.value, event.target) 
  }

  executeAjax(query, element) {
    const url = this.autocompleteUrlTarget.value + "?query=" + encodeURIComponent(query);

		let XHR = new XMLHttpRequest()

		XHR.addEventListener("load", response => {
			this.populateAutocompleteList(response.target.response, element);
		})

		XHR.addEventListener("error", event => {
			alert('Sorry! Something went wrong. Please try reloading this page.');
		})

		XHR.open('GET', url)
		XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
		XHR.send()
  }

  populateAutocompleteList(response, element) {
		this.clearAutocompleteList()
	  const materials = JSON.parse(response);

		/*for each item in the array...*/
		for (let i = 0; i < materials.length; i++) {
			/*create a DIV element for each matching element:*/
			const b = document.createElement("DIV");
			/*make the matching letters bold:*/
			b.innerHTML = "<span>" + materials[i].description.substr(0, this.materialDescriptionTarget.value.length);
			b.innerHTML += materials[i].description.substr(this.materialDescriptionTarget.value.length);
			b.innerHTML += ` (${ materials[i].uom})</span>`;
			/*insert a input field that will hold the current material item's value:*/
			b.innerHTML += "<input type='hidden' value='" + escape(materials[i].description) + "' name='description'>";
			b.innerHTML += "<input type='hidden' value='" + materials[i].uom + "' name='uom'>";
			/*execute a function when someone clicks on the item value (DIV element):*/
			b.setAttribute('data-action', 'click->material-autocomplete#pasteMaterial')
			element.nextSibling.append(b);
		}
	}

  pasteMaterial() {
		const autocompleteContainer = event.target.closest('.autocomplete-container')
		const chosenMaterialDiv = event.target.closest('[data-action="click->material-autocomplete#pasteMaterial"]')
		const chosenMaterialDescription = unescape(chosenMaterialDiv.querySelector('input[name="description"]').value)
		const chosenMaterialUom = chosenMaterialDiv.querySelector('input[name="uom"]').value

		
		autocompleteContainer.nextSibling.querySelector('[data-material-autocomplete-target="materialUom"]').value = chosenMaterialUom
		
		autocompleteContainer.querySelector('[data-material-autocomplete-target="materialDescription"]').value = chosenMaterialDescription
		
		// autocompleteContainer.querySelector('input[type="text"]').value = chosenMaterialDescription

		this.clearAutocompleteList()
  }

  clearAutocompleteList() {
    this.autocompleteListTargets.forEach(target => {
			target.innerHTML = ''
		})
  }

	// autocomplete() {
	// 	if (this.inputFieldTarget.value.length <= 1) {
	// 		return false
	// 	}

	// 	this.closeAutocompleteLists();
	// 	const a = document.createElement("DIV");
	// 	a.setAttribute("id", "autocomplete-list");
	// 	a.setAttribute("class", "autocomplete-items");
	// 	a.setAttribute("data-target", "autocomplete-car-number-plate.autocompleteList");
	// 	/*append the DIV element as a child of the autocomplete container:*/
	// 	this.autocompleteContainerTargetx.appendChild(a);

	// 	const url = '/cars/autocomplete_number_plate/' + this.inputFieldTarget.value;

	// 	let XHR = new XMLHttpRequest()

	// 	XHR.addEventListener("load", event => {
	// 		this.populateAutocompleteList(event.target.response);
	// 	})

	// 	XHR.addEventListener("error", event => {
	// 		alert('Sorry! Something went wrong. Please try reloading this page.');
	// 	})

	// 	XHR.open('GET', url)
	// 	XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
	// 	XHR.send()
	// }

	// populateAutocompleteList(response) {
	// 	const carPlates = JSON.parse(response);

	// 	if(carPlates.length <= 0) {
	// 		this.closeAutocompleteLists();
	// 	}

	// 	/*for each item in the array...*/
	// 	for (let i = 0; i < carPlates.length; i++) {
	// 		/*create a DIV element for each matching element:*/
	// 		const b = document.createElement("DIV");
	// 		/*make the matching letters bold:*/
	// 		b.innerHTML = "<strong>" + carPlates[i].substr(0, this.inputFieldTarget.value.length) + "</strong>";
	// 		b.innerHTML += carPlates[i].substr(this.inputFieldTarget.value.length);
	// 		/*insert a input field that will hold the current carPlatesay item's value:*/
	// 		b.innerHTML += "<input type='hidden' value='" + carPlates[i] + "'>";
	// 		/*execute a function when someone clicks on the item value (DIV element):*/
	// 		b.setAttribute('data-action', 'click->autocomplete-car-number-plate#pasteNumberPlate')
	// 		this.autocompleteListTarget.appendChild(b);
	// 	}
	// }

	// pasteNumberPlate() {
	// 	this.inputFieldTarget.value = event.currentTarget.querySelector('input').value
	// 	this.closeAutocompleteLists();
	// }

	// closeAutocompleteLists() {
	// 	if(this.hasAutocompleteListTarget) {
	// 		this.autocompleteListTarget.remove()
	// 	}
	// }
}