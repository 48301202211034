import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "grandTotal", "marker", "unitPriceField", "quantityField", "discountRateField", "subtotalField", "itemLine", "paymentTerm", "personInChargeName", "personInChargeContact", "supplierInput", "supplierUrl" ]

  connect() {
    this.refreshMarker()
  }

  calculate() {

    this.calculateLines()

    this.calculateGrandTotalAmount()
    // this.calculateItemLines()
    // this.calculateGrandTotal()
    // const overtimeHours = this.coerceNumber(line.querySelector('[data-payday-target="overtimeHours"]').value)
  }

  changedSupplierInput() {
    // debuggers
    if(event.target.value == '') {
      this.paymentTermTarget.value = ''
      this.personInChargeNameTarget.value = ''
      this.personInChargeContactTarget.value = ''
    } else {
      const url = `${ this.supplierUrlTarget.value }?supplier_id=${ event.target.value }`

      let XHR = new XMLHttpRequest()

      XHR.addEventListener("load", response => {
        this.pasteSupplierDetails(response.target.response);
      })
  
      XHR.addEventListener("error", event => {
        alert('Sorry! Something went wrong. Please try reloading this page.');
      })
  
      XHR.open('GET', url)
      XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      XHR.send()
    }
  }

  pasteSupplierDetails(response) {
    const parsedResponse = JSON.parse(response)
    
    this.paymentTermTarget.value = parsedResponse.payment_terms
    this.personInChargeNameTarget.value = parsedResponse.person_in_charge_name
    this.personInChargeContactTarget.value = parsedResponse.person_in_charge_contact
  }

  calculateLines(){
    this.itemLineTargets.forEach(line => {
      this.calculateLine(line)
    })
  }

  calculateLine(line){
    const unitPrice = this.coerceNumber(line.querySelector('[data-purchase-order-target="unitPriceField"]').value)
    const quantity = this.coerceNumber(line.querySelector('[data-purchase-order-target="quantityField"]').value)
    const discountRate = this.coerceNumber(line.querySelector('[data-purchase-order-target="discountRateField"]').value)

    line.querySelector('[data-purchase-order-target="subtotalField"]').value = ((unitPrice - discountRate) * quantity).toFixed(2)
  }

  calculateGrandTotalAmount() {
    const grandTotal = this.subtotalFieldTargets.map(field => field.value).reduce((sum, x) =>
      this.coerceNumber(sum) + this.coerceNumber(x)
    )

    this.grandTotalTarget.value = this.coerceNumber(grandTotal).toFixed(2)
  }

  markerTargetConnected() {
    this.refreshMarker()
  }

  markerTargetDisconnected() {
    this.refreshMarker()
  }

  refreshMarker() {
    this.markerTargets.forEach((marker, index) => {
      marker.value = index + 1
    })
  }

  coerceNumber(maybe_number) {
    const maybe_num = parseFloat(maybe_number)

    return isNaN(maybe_num) ? 0.00 : maybe_num
  }
}