import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "daysWorked", "dayRate", "totalRate", "overtimeHours", "overtimeRate", "totalOvertimeRate", "grossTotal", "deductionAmount", "totalAmount", "paydayLine", "grandTotalAmount", "checkbox", "othersAmount" ]

  connect() {
    // if (this.hasCheckboxTargets) {
    //   const checkboxes = this.checkboxTargets.filter(checkbox => checkbox.checked);

    //   checkboxes.forEach(checkbox => {
    //     checkbox.closest('tr')
    //   })
    // }

    this.paydayLineTargets.forEach(line => {
      this.toggleFields(line.querySelector('[data-payday-target="checkbox"]'))
      // if (line.querySelector[])
      this.calculateLine(line)
    })
    this.calculateGrandTotalAmount()
  }

  toggleFields() {
    this.paydayLineTargets.forEach(line => {
      this.disableLine(line)
    })
  }

  disableLine(line) {
    const paydayLine = line.closest('tr')
    // debugger
    line.querySelectorAll('input[type="number"]').forEach(input => {
      if(line.querySelector('[data-payday-target="checkbox"]').checked) {
        input.disabled = false
      } else {
        input.disabled = true
      }
     })
  }

  calculate() {
    const paydayLine = event.target.closest('tr')

    this.calculateLine(paydayLine)

    this.calculateGrandTotalAmount()
  }


  calculateLine(line) {
    this.calculateDayRateTotal(line)
    this.calculateOvertimeRateTotal(line)
    this.calculateGrossTotal(line)
    this.calculateNetTotal(line)
  }

  calculateDayRateTotal(line) {
    const daysWorked = this.coerceNumber(line.querySelector('[data-payday-target="daysWorked"]').value)
    const dayRate = this.coerceNumber(line.querySelector('[data-payday-target="dayRate"]').value)
    line.querySelector('[data-payday-target="totalRate"]').value = (daysWorked * dayRate).toFixed(2)
  }

  calculateOvertimeRateTotal(line) {
    const overtimeHours = this.coerceNumber(line.querySelector('[data-payday-target="overtimeHours"]').value)
    const overtimeRate = this.coerceNumber(line.querySelector('[data-payday-target="overtimeRate"]').value)
    line.querySelector('[data-payday-target="totalOvertimeRate"]').value = (overtimeHours * overtimeRate).toFixed(2)
  }

  calculateGrossTotal(line) {
    const overtimeTotal = this.coerceNumber(line.querySelector('[data-payday-target="totalOvertimeRate"]').value)
    const dayRateTotal = this.coerceNumber(line.querySelector('[data-payday-target="totalRate"]').value)
    line.querySelector('[data-payday-target="grossTotal"]').value = (overtimeTotal + dayRateTotal).toFixed(2)
  }

  calculateNetTotal(line) {
    const grossTotal = this.coerceNumber(line.querySelector('[data-payday-target="grossTotal"]').value)
    const deductionAmount = this.coerceNumber(line.querySelector('[data-payday-target="deductionAmount"]').value)
    const othersAmount = this.coerceNumber(line.querySelector('[data-payday-target="othersAmount"]').value)
    line.querySelector('[data-payday-target="totalAmount"]').value = ((grossTotal - deductionAmount) + othersAmount).toFixed(2)
  }

  calculateGrandTotalAmount() {
    const checkboxes = this.checkboxTargets.filter(checkbox => checkbox.checked);

    const grandTotal = checkboxes.map(checkbox => checkbox.closest('tr').querySelector('[data-payday-target="totalAmount"]').value).reduce((sum,x) =>
      this.coerceNumber(sum) + this.coerceNumber(x)
    )

    this.grandTotalAmountTarget.value = this.coerceNumber(grandTotal).toFixed(2)
  }


  coerceNumber(maybe_number) {
    const maybe_num = parseFloat(maybe_number)

    return isNaN(maybe_num) ? 0.00 : maybe_num
  }
}