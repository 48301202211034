document.addEventListener('turbo:load', function(){
	document.addEventListener("click", function (e) {
		closeAutocompleteList();
	});
})


function closeAutocompleteList() {
	const autocompleteList = document.querySelector('.autocomplete-list');

	if(autocompleteList != undefined) {
		autocompleteList.innerHTML = ''
	}
}
