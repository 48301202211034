import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["checkbox", "inputField"]

  connect() {
    console.log('hi')
    // this.checkboxTargets.forEach(checkbox => (
    //   this.toggleRequiredInput()
    // ))
  }

  toggleRequiredInput() {
    const tableRow = event.target.closest('tr')
    const inputField = tableRow.querySelector('[data-wage-advance-target="inputField"]')

    if(event.target.checked) {
      inputField.required = true
    } else {
      inputField.required = false
    }
  }
}